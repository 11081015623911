import React, { useState } from 'react'
import './Thanks.scss'
import Logo from '../../Images/Logo.png'

import { Helmet } from "react-helmet";
// var __html = require('./ThanksJS.html');

export default function Thanks({ navigateTo }) {
    const styles = {} // if you want to add some custom CSS
    const URL = "https://form.jotform.com/jsform/231987996785081" //embed URL

    return (
        <div className="Thanks">
            <div className="ThanksBox">
                <h1>Torrance Garage Door</h1>
                <h3>Thank you for book Garage Door services.</h3>

            </div>

            <div className="HowItWorkMainExplain">
                <div className="HowItWorkMainExplainHeader">
                    <h1>Your appointment has been successfully created:</h1>
                    <h2>Thank you for booking your appointment with Torrance Garage Door! We appreciate your trust in our services. Our team will confirm your appointment shortly. If you have any questions or need to reschedule, please contact us at 424-404-6606. We look forward to ensuring your garage is safe and efficient.</h2>
                </div>

                <div className="HowItWorkMainWhyUsBorder"></div>
            </div>

            <div className="ThanksBottom">
                <h6>
                    Sincerely,
                    <br />
                    Torrance Garage Door 
                </h6>
                <h6>
                    We've put together a pages with some helpful resources for you.
                    <br />
                    <br />
                </h6>
                <span>
                    <a target='_blank' href='/'>Take me to Home Page</a>
                </span>

            </div>
        </div>
    )
}


