const LocationReviewsData = [
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Jamie English MD",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kayla Y.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Tonya Nelson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Debra C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Laura Walton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert L.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Melinda Maxwell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kathleen R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Rhonda Cochran",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David D.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Dr. David Walton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kimberly A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Mary Bishop",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Aaron Charles",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Danielle B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Michelle Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sally M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Kevin Rogers Jr.",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Patricia D.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Robert Jackson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Gregory K.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Dr. Lisa Rivera",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gabriel H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Chad Stewart",
        "service_type": "Garage Door Off Track",
        "tech_name": "Susan M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Clifford Baker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Melinda W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.8,
        "customer_name": "Carol Clarke",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Randy S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Robert Moore",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jennifer M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Monica Graham",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bryan O.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Christopher Villarreal",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Paul J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Kevin Clark",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Aaron W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Manuel Anderson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Peggy H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Brenda Gibbs PhD",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Carla O.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Tara Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Anna Solomon",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeffrey S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Casey Moreno",
        "service_type": "Garage Door Installation",
        "tech_name": "Daisy S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Lisa Anderson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Linda C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Stephen Wright",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Billy R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Brianna Valdez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Nicole S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Sean Ayala",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joseph C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Megan Wright",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nathan S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Megan Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Melanie D.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Dustin Guerrero",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gary R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "William Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Angela W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Mark Henson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Anthony H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Brandi Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Andrew W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Amy Nguyen",
        "service_type": "Garage Door Installation",
        "tech_name": "Bryce P.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Crystal Tran",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Vanessa B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Kelly Robinson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Mike E.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Matthew Howard",
        "service_type": "Garage Door Services",
        "tech_name": "Susan M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Wendy Choi",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kimberly P.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Miguel Miller",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Martin W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Michele Wheeler",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Bruce P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Robert Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brian M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Mrs. Alison Riddle",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Barbara B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Jennifer Velasquez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Justin Robinson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Courtney T.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Bob Griffin",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Douglas K.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Xavier Bond",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jamie L.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Christopher Bartlett",
        "service_type": "Garage Door Repair",
        "tech_name": "Anthony F.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Brian Erickson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ronald J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Brent Robbins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mark J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "James Medina",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Shawn S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Jason Navarro",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jack T.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Robert Davis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gregory M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Thomas Tanner",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Raymond D.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Alicia Garcia",
        "service_type": "Garage Door Off Track",
        "tech_name": "Chad R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "James Williams",
        "service_type": "Garage Door Repair",
        "tech_name": "Kenneth T.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Taylor Mcdonald",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mary H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Pamela Garcia",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amy S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Gabriel Hopkins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tracy J.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Amber Long",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Matthew T.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Derek Jones",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "April W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Sara Mills",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Donald F.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Craig Wolf",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer T.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Kelly Swanson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lisa H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Robyn Avila",
        "service_type": "Garage Door Off Track",
        "tech_name": "Alicia S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Denise Scott",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Stephen Marquez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amanda M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Douglas Greene",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Kellie Collins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Aaron B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Madison Brooks",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jeffrey K.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Trevor Cervantes",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jeffrey D.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Mark Thomas",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Donna F.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Kathy Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Diane H.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Steven Porter",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Laura F.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Hannah Sandoval",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jonathan N.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Cynthia Williams",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Brooke Jackson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dean R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Amanda Morton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Matthew H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Mitchell",
        "service_type": "Garage Door Repair",
        "tech_name": "Wendy W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Cheyenne Rose",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ryan F.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Robin Chapman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Patrick W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Daniel Davis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.8,
        "customer_name": "Michael Wilson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Miranda H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Russell Reeves",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Melissa N.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Kelsey Ward",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Monica B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Jordan Graham",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Carla K.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Mark Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Susan G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Robert Mahoney",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Penny D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Andrea Hancock",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cristina G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Victoria Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Eric D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Billy Hall",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Breanna K.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Robyn Webster",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Margaret L.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Sandra Carter",
        "service_type": "Garage Door Opener",
        "tech_name": "James M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Shawn Green",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nancy D.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Renee Martin",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Katherine W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Jason Lewis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Linda C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Edward Lambert",
        "service_type": "Garage Door Off Track",
        "tech_name": "Billy R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Carolyn Sanders",
        "service_type": "Garage Door Installation",
        "tech_name": "Tiffany T.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Wang",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Cathy L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Teresa Ortiz MD",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jasmine W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Brian Daniels",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ashley R.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Mr. Kelly Salazar",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kimberly L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Ashley Howard",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kathryn B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Rhonda Bowers",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Stephanie K.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Daniel Schmidt",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Andrea M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Henry Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joseph M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Julia Morales",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Christopher Castillo",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kim D.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Dyer",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "John G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Daniel Boyd",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kristina W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Sharon Graves",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Brian Lee",
        "service_type": "Garage Door Off Track",
        "tech_name": "Susan V.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Olivia Pham",
        "service_type": "Garage Door Opener",
        "tech_name": "Kayla R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Amber Ponce",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nathan S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Melanie Berry",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Megan S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Kara Larsen",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joe S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Harold Oliver",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Emily F.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Anita Henderson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kristie S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Matthew Mitchell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Robert Carter",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Chad G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Juan Oneal",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tamara M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "James Hernandez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andrew C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "James Herrera",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tony Y.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Dana Bryant",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jill S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Matthew Patrick",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ashley K.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Jenna Schroeder",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Connie C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Michael Hill",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Colleen Reyes",
        "service_type": "Garage Door Installation",
        "tech_name": "Eric W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Tammie Miller",
        "service_type": "Garage Door Opener",
        "tech_name": "James M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Amber Romero",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Benjamin L.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Shelly Gonzalez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Andrew G.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Jessica Wells",
        "service_type": "Garage Door Installation",
        "tech_name": "David K.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Michael Morales",
        "service_type": "Garage Door Services",
        "tech_name": "Michael D.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Angela Kelly",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Gallagher",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tammy B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Mrs. Whitney Arnold DVM",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kimberly A.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Wesley Cabrera",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mary A.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Ryan Wright",
        "service_type": "Garage Door Off Track",
        "tech_name": "Katie C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "David Mccarthy",
        "service_type": "Garage Door Repair",
        "tech_name": "Roger A.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Allison Matthews",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Carl C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Mrs. Crystal Levy",
        "service_type": "Garage Door Insulation",
        "tech_name": "Daisy H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Stacey Evans",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Maurice T.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Thomas Sherman",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Monica H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Timothy Johnston",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tiffany C.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Christopher Burke",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michelle G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Jennifer Mccoy",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jacob R.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Donald Cox",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Rachel S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Tina Jones",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jerry M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Susan Jackson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mandy M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Jasmine Duncan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tammy H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Kathryn Hartman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John J.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Flynn",
        "service_type": "Garage Door Installation",
        "tech_name": "Katherine W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Harold Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brenda C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Emma Pena",
        "service_type": "Garage Door Opener",
        "tech_name": "Micheal P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Jesus Mitchell MD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Elizabeth T.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Melissa Webster",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lisa N.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Michael Knight",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Marcus S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "John Thompson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amy L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "William Adams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kristin B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Preston Stone",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Anne A.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Patty Martin",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brittany A.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Amber English",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Laura W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "David Wagner",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christina F.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Julie Miles",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michelle V.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Terry Hunter",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Laura Sanders",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nathan L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Jason Greer",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Patrick T.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Juan Mann",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christina H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Dr. Christian Lowery",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Susan D.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Jessica Harvey",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brett K.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Cindy Jones",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Thomas Morrison",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Arthur M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Keith Tran",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michelle I.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Kathleen Harris",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joshua W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Jeremiah Ford",
        "service_type": "Garage Door Services",
        "tech_name": "Jill L.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Kenneth Davis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mary M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Tina Murray",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Vanessa D.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Teresa Bauer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brenda P.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Jason Rivera",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Logan T.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Edward Ramsey",
        "service_type": "Garage Door Repair",
        "tech_name": "Jaime C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Thomas Anderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joan C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "James Walters",
        "service_type": "Garage Door Repair",
        "tech_name": "Garrett C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Katherine Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kathy H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Thomas Powers",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Todd B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Natalie Cordova",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jason M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Robert Anderson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lauren L.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Julie Marshall",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Jackie Shepherd",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Eric G.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Jack Reeves",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amy M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Cole Hall",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Philip N.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Luis Moore",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brittany C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Katelyn Schwartz",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Hunter M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Steven Stone",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Matthew F.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Katelyn Murray",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michelle P.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Jason Morgan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David D.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Matthews",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Timothy Young",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jose E.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Karen Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tracy T.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Jean Schroeder",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Devin G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Felicia Davila",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Renee V.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Anna Herrera",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kimberly A.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Kelly Sanchez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Virginia L.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Brandon Gordon",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Katherine H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Robert Gordon",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ebony B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Alex Bauer",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joel M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Judith Hebert",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joseph B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Miller",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Daniel C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Nicole Hood",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Fernando M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Laura Spencer",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ashley W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Gary White",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Louis R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Lisa Garcia",
        "service_type": "Garage Door Opener",
        "tech_name": "Ashley D.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Shane Cooper",
        "service_type": "Garage Door Opener",
        "tech_name": "Angela P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Nicholas Vaughan",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lisa H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Eric Cruz",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Christopher Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tiffany R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Oscar Jenkins",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jeff W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Andrea Richards",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Ian Serrano",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Latoya B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Laura Kim",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Eric J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Nicholas Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kayla S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Rebecca Young",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Rhonda P.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Laura Moore",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Katie M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Molly Gomez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jennifer H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "April Dixon",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kristen T.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Todd Rodriguez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brian D.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Christina Daugherty",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jasmine M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Mrs. Ashley Nichols",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Cameron C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Tracy Harper",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Holly J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Curtis Alvarado",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tracey C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Mrs. Jennifer Coleman",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Elizabeth R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Melissa Flores",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Katherine M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Jason Murphy",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Benjamin C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Stephanie Berry",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Randall K.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Mary Campbell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Katelyn S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Molly Foster",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jamie B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Francisco Velez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "George F.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Lori Little",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jason W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Jacob Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jennifer M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Michael Potter",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Timothy H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Nicole Lucas",
        "service_type": "Garage Door Repair",
        "tech_name": "Bryan G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Scott Henderson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeffrey A.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Beverly Howard",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Paul A.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Colleen Vasquez MD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jose B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Perry Ortega",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rhonda J.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Derrick Miller",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Stacey W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Dustin Mathis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jane O.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Christopher Owens",
        "service_type": "Garage Door Services",
        "tech_name": "Tammy H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Courtney Evans",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Yvette B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Cheryl Warren",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joanne A.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "John Jacobs",
        "service_type": "Garage Door Opener",
        "tech_name": "Ryan K.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Sonya Woods",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kristin B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Steven Moore",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jason A.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Pamela Calhoun",
        "service_type": "Garage Door Repair",
        "tech_name": "Phillip H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Jason Allen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Heather M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Ian Stevens",
        "service_type": "Garage Door Repair",
        "tech_name": "Sara B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Hannah F.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Katelyn Hernandez",
        "service_type": "Garage Door Services",
        "tech_name": "Todd B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Christina Rice",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jesse G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Philip Reed Jr.",
        "service_type": "Garage Door Opener",
        "tech_name": "Kimberly V.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Wayne Lin",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Derrick W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Megan Nelson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brandon S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Mike Lewis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael A.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Matthew Gutierrez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michelle Y.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Tammy Garcia",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ryan T.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Ian Stevens",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Allison E.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Justin Wilcox",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Regina S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Brittney Shields",
        "service_type": "Garage Door Installation",
        "tech_name": "Danielle T.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Heidi Figueroa",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tracy S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Mark Jenkins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Haley B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Dale Campbell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Nicholas Taylor",
        "service_type": "Garage Door Opener",
        "tech_name": "Timothy G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Laurie Kelley",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kerry T.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-05",
        "review_rate": 5.0,
        "customer_name": "James Davis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Joshua Villanueva",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Melissa M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Melissa Riley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Hunter G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Natasha Torres",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Matthew H.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Mark Welch",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Charles M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Ashley Gould",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher L.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Mark Stanley",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jennifer S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "John Lopez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Adam T.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Morgan Wells",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Larry M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Brian Holmes",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Scott G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Jessica Lewis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Erica A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Nancy Fox",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kristy M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Christine Alvarez",
        "service_type": "Garage Door Off Track",
        "tech_name": "John C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Laura Gonzalez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Grace S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Leslie Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Joyce M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Robin Martin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "John Glass",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kevin D.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Maria Weaver",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Leslie B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Courtney Wolf",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ashley P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "Kenneth Ali",
        "service_type": "Garage Door Insulation",
        "tech_name": "Danielle J.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Kari Butler",
        "service_type": "Garage Door Repair",
        "tech_name": "Sean S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Nancy Allison",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Edward D.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Matthew Hill",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nicholas V.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Nathan Newton",
        "service_type": "Garage Door Opener",
        "tech_name": "Julia D.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Brian Caldwell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Crystal W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Makayla Gilbert",
        "service_type": "Garage Door Off Track",
        "tech_name": "Derek A.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Aaron Myers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Theresa P.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Jeremy Morgan",
        "service_type": "Garage Door Opener",
        "tech_name": "Jodi A.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Anita Long",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael L.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "John Martinez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Leah A.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James G.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Christine Hester",
        "service_type": "Garage Door Services",
        "tech_name": "Tyler G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "John Williams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Natasha B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Amy Garcia",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Timothy F.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Jonathan Fox",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "David R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Anna Santana",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Melissa Rangel",
        "service_type": "Garage Door Opener",
        "tech_name": "Kurt P.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "James Miller",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Krystal C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Lauren White",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Deborah B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "James Hammond",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kevin B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Mrs. Ashley Cohen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sara P.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Robert Sawyer",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Cory B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Angela Jordan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brian W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Leah Morgan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Daniel Vargas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Krista W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Nicole Graham",
        "service_type": "Garage Door Insulation",
        "tech_name": "Briana C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Jasmin Ochoa",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Troy Bailey",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Larry F.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Michael Mitchell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Elizabeth H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Melanie Taylor",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Adams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicholas S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Gabriel Davenport",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Peter H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Betty Anderson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christine P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Jamie Johnston",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brian A.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "John Byrd",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jennifer T.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Tara Nichols",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mark F.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Patrick Good",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Judy A.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Nathan Mendoza",
        "service_type": "Garage Door Repair",
        "tech_name": "Mary R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Bonnie Boyd",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kristin S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Manuel Buckley",
        "service_type": "Garage Door Installation",
        "tech_name": "Michele P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Angela Sutton",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michelle M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Gabriella Harris",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Maria M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Gregory Mckee III",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Isaac M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "David Fisher",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "David H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Kevin Graham",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nancy H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Linda Duncan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Justin R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Clinton Chan",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Victoria D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Nathan Nguyen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ashley S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Ashley Jackson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Whitney L.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Charles Walker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Ashley May",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher T.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Angela Kelly",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Vanessa F.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Lisa Yoder",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Meredith S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Kenneth Webb",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michelle T.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Larry Davis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Heather S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Amber Cook",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Henry L.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Heidi Carlson",
        "service_type": "Garage Door Services",
        "tech_name": "Jeremiah C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Sandra Henry",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nicholas B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Jonathan Bradley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kimberly Y.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Charles Mendoza",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Daniel S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Michael Rowe",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kristin A.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Gary Morgan",
        "service_type": "Garage Door Insulation",
        "tech_name": "Teresa W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Paul Armstrong",
        "service_type": "Garage Door Opener",
        "tech_name": "Nathaniel G.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Julia Bartlett",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Raymond W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Christopher Rodriguez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mikayla J.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Madison Webb",
        "service_type": "Garage Door Opener",
        "tech_name": "Grant B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Tyler Daniels",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amanda R.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Abigail Davis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michael V.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Andrew Garcia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gregory K.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Debra Contreras",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Erik C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Earl Alvarez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brandon S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Alexander Figueroa",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robert B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Jerry Lopez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Victoria B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-03",
        "review_rate": 5.0,
        "customer_name": "Lisa Quinn",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Melissa B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Robert Wallace MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Donna W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Jay Ibarra",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Nathaniel Griffin",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Dana L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Kyle Boyd",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Robert C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Jessica Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joseph T.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Olivia Baker",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Vicki W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Victoria Garrett",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher P.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Peter Griffin",
        "service_type": "Garage Door Opener",
        "tech_name": "Trevor P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Kathryn Kelly",
        "service_type": "Garage Door Installation",
        "tech_name": "Cody C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Madison Lopez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Paul R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Sheila Simon",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Elizabeth N.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Jennifer Thomas",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Erin S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Brent Klein",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Louis H.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Albert Coleman",
        "service_type": "Garage Door Installation",
        "tech_name": "Jeanne B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Betty Garcia",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "William B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Mr. James Ferrell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Monique Richards",
        "service_type": "Garage Door Opener",
        "tech_name": "Daniel W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Lindsay Baker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ralph E.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Zachary Green",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Nicole L.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Cameron Daniels",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Heather R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Gabriel Mercer",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Julie S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Jerome Mclaughlin",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brooke K.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Amber Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer A.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Timothy Thornton",
        "service_type": "Garage Door Services",
        "tech_name": "Joshua A.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Louis Richardson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Adam C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Kimberly Baker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gregory S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Lindsey Wheeler",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nicole P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Jacob Taylor",
        "service_type": "Garage Door Opener",
        "tech_name": "Joseph P.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Megan Martinez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lori M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Brandon Tran",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Terry A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Erin Wilcox",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rebecca Y.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Susan Manning",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Walter B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Steven Martinez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alyssa C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Randall Rice",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Amy J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Kimberly Leon",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robin C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Jessica Wise",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Carly S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Cynthia Cooley",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stephen S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Connor Hoffman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Krystal H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Williams MD",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Nicholas S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Valerie West",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kevin D.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Lonnie Thomas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amy L.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Emma Beasley",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Benjamin C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Mary Woodward",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lisa C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Michelle Gibbs",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dana H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Tammy Weaver",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jon M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Brandy Myers",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Deanna S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Nicole Moon",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joe O.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Stephanie Campbell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mark F.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Dennis Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sharon G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Denise Harmon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Anne O.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Teresa Madden",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Raymond P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "John Leon",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kristen W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Andrew Finley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Angela H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Brian Osborne",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sean D.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Eddie Turner",
        "service_type": "Garage Door Insulation",
        "tech_name": "April C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Stephanie King",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Taylor B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "John Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Holly M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Angela Luna",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Douglas J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Alec Levine",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Keith H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Brenda Brown",
        "service_type": "Garage Door Services",
        "tech_name": "Daniel C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Howard Griffin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sean A.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Christopher Cunningham",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Mercedes Gillespie",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Robert Nichols",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Edward H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.8,
        "customer_name": "Manuel Cummings",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael T.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Kristina Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kenneth W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Gregory Lee",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Timothy Caldwell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John P.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Jennifer Dixon",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Robert Garcia",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Elijah B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Dustin Collins",
        "service_type": "Garage Door Repair",
        "tech_name": "Dana B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Dan Wilson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Veronica B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Bradley Mcclure",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Travis B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Kathleen Miller",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tim M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Nina Case",
        "service_type": "Garage Door Repair",
        "tech_name": "William A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Steven Ramirez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amanda W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Joshua Burton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Karen F.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Erik Gregory",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jeffrey S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Travis Morris",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Cheryl Mccarty DDS",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Charles T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Christopher Mcclain",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Thomas G.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Michael Vaughn",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Anthony W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Barbara Wood",
        "service_type": "Garage Door Insulation",
        "tech_name": "Krystal M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Sandra Blake",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Paula W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Stacy Ramirez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Destiny N.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Clarence Mills",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michelle G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "John Rivas",
        "service_type": "Garage Door Repair",
        "tech_name": "Joshua S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Jerry Lee",
        "service_type": "Garage Door Opener",
        "tech_name": "Nancy A.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Tonya Miranda",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Maureen W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Justin Rivera",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Austin P.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Joseph Watson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jessica B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Brent White",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Angela J.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Sean Frank",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Matthew Mclaughlin",
        "service_type": "Garage Door Insulation",
        "tech_name": "John B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Ryan Ryan",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher P.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Luis Jones",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joshua F.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Anthony Esparza",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Miranda A.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Barbara Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Aimee M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Thomas Harding",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Clayton L.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Nicole Moore",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Whitney M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Patricia Moore",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Teresa M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Amanda Bean",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David K.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Ashley Bowers",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kelly G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Tabitha Wiley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Katherine G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Tommy Booth",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Leslie S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Michelle Wells",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "George H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Jackson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kelli W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Michelle Ware",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Daniel L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Julie Thomas",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Dylan W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Angela Jensen",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Cheryl A.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.8,
        "customer_name": "Nicholas Hernandez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Wesley N.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Larry Rose",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Matthew D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Lee",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "David C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Cory Luna",
        "service_type": "Garage Door Services",
        "tech_name": "Nicole B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Emily Rowe",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jesus C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Charles Welch",
        "service_type": "Garage Door Services",
        "tech_name": "Erik B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Dawn Kirk",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cynthia H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Diane Hayden",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Amanda M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Susan Johnson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Jaime Huynh",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tonya W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Christopher Carlson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Hunter W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Patrick Caldwell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephanie H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Ethan Herrera",
        "service_type": "Garage Door Installation",
        "tech_name": "Danielle J.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.8,
        "customer_name": "Jordan Wilson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Richard A.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Tyler Bolton DDS",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael R.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "James Black",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Evan C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Michelle Williams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jennifer W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Kimberly Ramirez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Terri M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Antonio Greene",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sarah S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "William Davis",
        "service_type": "Garage Door Repair",
        "tech_name": "Richard C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Kristin Miller",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brandon G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Dakota Guerrero",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Laura C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Rebecca Wright",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Briana B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Harris",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Mike H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Cynthia Williams",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Emily H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Samantha Lewis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Adam D.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Jessica Hudson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Cory Patel",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Cynthia A.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Austin Bass",
        "service_type": "Garage Door Installation",
        "tech_name": "Tyler H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Emily Sherman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kimberly L.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Mary York",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Debra C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Kristin Kelly",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Julie B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Kirsten Shields",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Katie R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Stephanie Chandler",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joseph H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Stacy Wilcox",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mark S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Erica Carpenter",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephanie A.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Stacey Moore",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Devin L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Clinton Gibson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Timothy J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Christopher Kennedy",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Theresa R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Kimberly Wyatt",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Vicki S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Jodi Friedman",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mark C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Maria Ryan",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Justin Z.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Jacob Richardson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kelsey D.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Carrie Middleton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lindsey T.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Travis Butler",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Peter M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Jason Hanna",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amy L.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Erin Sanchez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Samuel C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Wendy Phillips",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Andrew O.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Linda Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Julie D.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Brianna Todd",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Melissa M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Miss Kimberly Nelson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicholas A.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Amy Herrera",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Donna W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Emily Preston",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "George B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Sandra Allen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Daniel King",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Maurice G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Michelle White",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Catherine M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Brandon Brown",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael V.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Ralph Wise",
        "service_type": "Garage Door Repair",
        "tech_name": "Jimmy G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "James King",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Gregory F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "David Robinson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Michael Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lindsey G.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "David Butler",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jeffery Y.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "April Oconnell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joshua A.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Sean Bennett",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Krista S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Timothy Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tammy E.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Amanda Lewis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Eduardo L.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Penny Porter",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brenda M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Mitchell Gordon",
        "service_type": "Garage Door Opener",
        "tech_name": "Aaron C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Katrina Lee",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cynthia S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Donna Moore",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeffrey W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Whitney King",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robin P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Ricky Young",
        "service_type": "Garage Door Insulation",
        "tech_name": "Taylor B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Mr. Luke Brown",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kendra W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Victor Powell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nathan P.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Shawn Thomas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tina G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Denise Martinez",
        "service_type": "Garage Door Services",
        "tech_name": "Evan S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "William Owens",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michelle H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "David Valenzuela",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kathy N.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Lisa Blanchard",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Cassandra W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Joanne Hammond",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ryan P.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Robin Mcintyre",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lindsay P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Mallory Hill",
        "service_type": "Garage Door Installation",
        "tech_name": "Charles W.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Justin Simpson MD",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Leslie A.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Victor Webb",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sonya T.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Vincent Bruce",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joseph M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Alicia Wheeler",
        "service_type": "Garage Door Installation",
        "tech_name": "Austin K.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Gabriel Hill",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jared M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Justin Lewis",
        "service_type": "Garage Door Installation",
        "tech_name": "Michelle W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Rachel Weiss",
        "service_type": "Garage Door Opener",
        "tech_name": "Samuel G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Long",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Stephen S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Jennifer Dillon",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lori H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Makayla Ingram",
        "service_type": "Garage Door Services",
        "tech_name": "Jacqueline P.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Christina Newman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kyle B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Molly Mcclure",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Maria C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Ashley Fuller",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "John C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Kelly Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Valerie C.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Mallory Sanchez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Rebecca B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Michael Stewart",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Cynthia H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Christopher Wilkinson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nathan M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Rebecca Burke",
        "service_type": "Garage Door Off Track",
        "tech_name": "Abigail W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Benjamin Blake DDS",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Angela F.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Cynthia Tate",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Jason Edwards",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Eric C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Sarah Heath",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mark J.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Becky Bell",
        "service_type": "Garage Door Opener",
        "tech_name": "Timothy H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Carmen Vaughn",
        "service_type": "Garage Door Services",
        "tech_name": "Wayne G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Michael Schneider",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Connie W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Robert Parker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kelly R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Jerry Foley",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Deborah M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Daniel Murphy",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Felicia S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Mrs. Ashley Carroll MD",
        "service_type": "Garage Door Services",
        "tech_name": "Tyler G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Karla Jones",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michelle J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Joann Morales",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicholas K.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Kelly Jones",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Juan W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Courtney Lambert",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jonathan F.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Terry Marquez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Anthony T.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Joseph Summers",
        "service_type": "Garage Door Opener",
        "tech_name": "Chase M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Mark Rios",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christina W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Cindy Green",
        "service_type": "Garage Door Insulation",
        "tech_name": "Edward P.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Shirley King",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kathleen L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Michael Carter",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brian H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Shannon Tucker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Veronica J.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Marc Bennett",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeremy G.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Lisa Baldwin",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cynthia B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Sarah Lee",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Natalie F.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Jack Bell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Todd Z.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Jeff Garcia",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sara J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Nicole Munoz",
        "service_type": "Garage Door Services",
        "tech_name": "John L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Kenneth Harris",
        "service_type": "Garage Door Off Track",
        "tech_name": "Haley W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Colleen Cruz",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sarah M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Alicia Davis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jacob G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Samuel Tran",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Henry B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Brian Chandler",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mary C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Michael Harmon",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ryan M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Reginald Navarro",
        "service_type": "Garage Door Services",
        "tech_name": "Russell L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Mallory Sims",
        "service_type": "Garage Door Opener",
        "tech_name": "Penny S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Nathan Anderson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jacqueline E.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Crystal Martin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Erika G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Marisa Melendez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Wendy B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Jocelyn Singleton",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jessica S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Joann Hansen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Samantha W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Phyllis Adkins",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Cindy P.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Brandon Bullock",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Danny O.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Rebecca Harrison",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Derrick C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Nicholas Shaffer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mariah G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Bridget King",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joshua M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "April Obrien",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mary R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Donna Hensley",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alexis M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Stephanie Hogan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Patricia K.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Christopher Alvarado",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mary W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "Kathryn Cuevas",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Thomas M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Cheryl Carr",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "James Oconnor",
        "service_type": "Garage Door Repair",
        "tech_name": "Ethan E.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Amber Foster",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Heather K.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Hannah Lee",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Linda S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Sherri Carpenter",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lisa Y.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Lindsay Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ryan A.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Tara Little",
        "service_type": "Garage Door Opener",
        "tech_name": "Kenneth W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Karen Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amanda H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Amanda Cooley",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kevin P.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Justin Fritz",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Shelby P.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Ronald Hanson",
        "service_type": "Garage Door Installation",
        "tech_name": "Oscar L.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "Deborah Clark",
        "service_type": "Garage Door Insulation",
        "tech_name": "Marvin A.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Victoria Berg",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Madison S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Henry Nguyen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Daniel Miller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brian H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Julie Rivera",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Jasmine Yoder",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jacob P.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Raymond Wilkins",
        "service_type": "Garage Door Installation",
        "tech_name": "Bryan H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Amy Aguilar",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Destiny G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Quinn",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jennifer D.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Shawn Murillo",
        "service_type": "Garage Door Installation",
        "tech_name": "Carolyn M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Joseph Murray",
        "service_type": "Garage Door Repair",
        "tech_name": "Charles S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Kelly Henderson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Martin C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Rachel Taylor",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Aaron O.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Christine Ortiz PhD",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andrew S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Kathryn Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Andrew K.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Heather Mcfarland",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Traci O.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Samantha Hunt",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Teresa P.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Justin Phillips",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ashley G.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Colleen Vazquez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Erin J.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Kenneth Grant",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Andrew B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Rodney Anderson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Vincent T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Gregory Bates",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Patrick F.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "David Carter",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Dana W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Samantha Strickland",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kevin N.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Jenny Lee",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rebekah V.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Tiffany Rangel",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rachael N.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Ryan Moore",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Johnny R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Samuel James",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gregory D.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Jennifer Murphy",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Joseph Hill",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kaitlyn C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Jonathan Carter",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mark W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Maria Murphy",
        "service_type": "Garage Door Insulation",
        "tech_name": "Keith R.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Danielle Beasley",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Karen Powers",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rachel S.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Dustin Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Scott T.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Katherine Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lisa G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Heather Rivera",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lucas L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Eddie Romero",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kathryn G.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Peter Sanchez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "James H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Michelle Tucker",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Paul F.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Sean Foster",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Randall M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Deborah Garcia",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Vanessa Q.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Beth Higgins",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Linda W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Judith Kennedy",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Aimee I.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Cameron Conley",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Douglas S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Terry",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jeffrey J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Steven Tyler",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Glen B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Amber Farmer",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nancy W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Martha Moran",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sean V.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Jacob Camacho",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Dominique B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Douglas Phillips",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Julie H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Harris",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nathan G.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Daniel Lewis",
        "service_type": "Garage Door Services",
        "tech_name": "Aaron T.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Meghan Taylor",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Darlene J.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Theresa Cook",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jane B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Daniel Franklin",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robert A.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Robert Roberts",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sarah H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Stacey Hunt",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael K.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Michael Miranda",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Diane B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Amy Beck",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Adrian A.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Edward Novak",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kenneth C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Lindsey Alexander",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephanie L.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Anthony Rivas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stacy D.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Jonathan Wilson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Paul L.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Sheri Lewis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Wendy C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Dawn Eaton",
        "service_type": "Garage Door Opener",
        "tech_name": "Lisa B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Tiffany Hardy",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Theresa A.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Daniel Hopkins",
        "service_type": "Garage Door Repair",
        "tech_name": "Heidi C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Jamie Neal",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Fred T.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Tiffany May",
        "service_type": "Garage Door Repair",
        "tech_name": "Laura F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Antonio Duncan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Elizabeth B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Clinton Coleman DDS",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jasmine A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Harold Evans",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Monique C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Ryan Young",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jose N.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Maria Howard",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Timothy M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Scott Bell",
        "service_type": "Garage Door Repair",
        "tech_name": "Sandra J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Michael Williams",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tyler F.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Devon Beck",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Victor G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Heather King",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alyssa E.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Alicia Martin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Logan R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Gregory Stewart",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Anne O.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Benjamin Franklin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mark D.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Catherine Young",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amanda C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Ricky Parker",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michele L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Harris",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Douglas S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Robin Rodriguez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mary W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Richard Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Diana M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Breanna Myers",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tonya A.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "John Thompson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kevin R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Jon Cain",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Corey M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Andrea Dalton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jonathan S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Roberto Garcia",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jonathan L.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Michael Whitaker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dawn B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Jose Crawford",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Donna R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Chelsea Baker",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Nicole W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "Ann King",
        "service_type": "Garage Door Off Track",
        "tech_name": "Bryce F.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Monique Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Veronica P.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Emily Thornton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Lauren Beck",
        "service_type": "Garage Door Insulation",
        "tech_name": "Derek A.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Kyle Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Aaron L.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Michelle Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Deborah M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Mark Young",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Megan M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Dr. Crystal Fuller",
        "service_type": "Garage Door Off Track",
        "tech_name": "William R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Zachary Martin",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kathy B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Leah Horton",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Audrey H.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Benjamin Gray",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Steven F.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Cole Shaw MD",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kevin G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "William Jones",
        "service_type": "Garage Door Installation",
        "tech_name": "Dana D.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Roger Cole",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mary H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "John Salas",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Jessica Cordova",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Reginald L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Matthew Rivera",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Debra N.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Caitlin White",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Theodore W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Katherine Gomez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Allen G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Justin Torres",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Holly H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Lindsay Mueller",
        "service_type": "Garage Door Installation",
        "tech_name": "Dennis B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Mary Jones",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Kelly Valencia",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Andrew N.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Peggy Dougherty",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Rodney B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Rebecca Decker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Alexis P.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Eric Jenkins",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Hector W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Laurie Nichols",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Patrick T.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Jennifer Douglas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dillon S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Isaac Hudson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cody S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Brandon Mclean",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Clinton Y.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Kimberly Davis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Dawn C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Margaret Jackson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Teresa H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Swanson",
        "service_type": "Garage Door Installation",
        "tech_name": "Bianca R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Molly Gibson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mark M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Fuentes",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Kelly Young",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tina G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Rebecca Hill",
        "service_type": "Garage Door Insulation",
        "tech_name": "James N.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Jared Warren",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Taylor H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Jill Trujillo",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Scott R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Tricia Gates",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Monique Cortez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Susan P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Curtis Rocha",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Laura N.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Gordon Hall",
        "service_type": "Garage Door Repair",
        "tech_name": "Amber M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Victoria Cowan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sophia B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Bianca Patrick",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brian R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Alexandra Harrison",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Veronica L.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Martin Simon",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Levi N.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Ashley Bush",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrew B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Blake Schmitt DDS",
        "service_type": "Garage Door Off Track",
        "tech_name": "Megan H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Brittany Burke",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brittany H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Joel Mooney",
        "service_type": "Garage Door Installation",
        "tech_name": "Samantha S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Joseph Thomas",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jacob P.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Andrea Day",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jacob E.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Maria Mayer",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jon T.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Denise Estrada",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Paul W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Matthew Anderson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jason F.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Danielle Foster",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Anthony J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Daniel Wilson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John T.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Sean Good",
        "service_type": "Garage Door Installation",
        "tech_name": "James G.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Robert Mcdonald",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robin T.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Kenneth Fuentes",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Elizabeth R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Michael Snyder",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lindsey S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Evelyn Avila",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cynthia D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Krystal Jones",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Donald C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Melanie Robinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ryan H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Jennifer Reese",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ryan S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Kara Benton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Debra C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Michael Coffey",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jessica A.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Holly Rose",
        "service_type": "Garage Door Insulation",
        "tech_name": "Rebecca S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Christopher Parker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lisa C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Gina Wright",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michael A.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Rachel Hansen",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michelle G.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Nancy Roberts",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Beth H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Craig Nguyen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Victoria T.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Alyssa Roth",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeremy P.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "James Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jonathan B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Michelle Mann",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joseph R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Autumn Morgan",
        "service_type": "Garage Door Installation",
        "tech_name": "Mark G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Monica Barnes",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lisa L.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Sarah Bullock",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mark R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Matthew Fields",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Brenda King",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Jeremy Harris",
        "service_type": "Garage Door Opener",
        "tech_name": "Antonio K.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Thomas Wood",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Ashley Campbell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Harold M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Mr. Michael Clark",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alexa D.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Donna Watts",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "James Spears",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Robert R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "David Lawrence",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amy R.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Nancy Tran",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ronald S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Luis Park",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tamara T.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Pamela Mason",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Aaron B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Megan Taylor",
        "service_type": "Garage Door Off Track",
        "tech_name": "Steven P.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Andrea Olson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mark B.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Robert Jenkins",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael F.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Jason White",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kelsey D.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Brett Houston",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gregory J.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Danielle Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jonathan B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Scott Mcneil",
        "service_type": "Garage Door Opener",
        "tech_name": "David C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Paula White",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Thomas W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Jeremiah Keith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Steven B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Veronica Harper",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Aaron J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Courtney Black",
        "service_type": "Garage Door Off Track",
        "tech_name": "John T.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Laura Clark",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jasmine B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Mrs. Sierra Armstrong",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Daniel C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Christine Reyes",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Daniel H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Nicole Carroll",
        "service_type": "Garage Door Repair",
        "tech_name": "Michelle B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Linda Jimenez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Carolyn L.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Rachel Lambert",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Nicholas H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Pamela Forbes",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Marc B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Sara Pennington",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ricardo F.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Sara Tucker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amanda H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Nicole Strickland",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jonathan C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "John Holt",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Rachel Lee",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jesse G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Andrea Banks",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jeremy W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Kevin Greene",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Terri M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Debra Richardson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer V.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Anthony Adams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Adrian P.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Dale Reilly",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Louis C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Joseph Rodriguez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Julia H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Kimberly Gonzalez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kathy S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Aimee Rodriguez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ana H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "David Green",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Emily S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Robert Page",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kyle Z.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Timothy Lewis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mary N.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Cooper",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Erica Jensen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joshua G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Ashley Cruz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Laura M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Julia Morris",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jerry W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Sheila Vasquez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Charles S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Zachary Rodriguez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Diane M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Jessica Rivera",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kim C.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Jonathan Riddle",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John D.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Olivia Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Christina Finley",
        "service_type": "Garage Door Services",
        "tech_name": "David C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Alec Cameron",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Denise S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Charles Lee",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brian B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Ochoa",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tracy H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Valerie Hunter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kristi M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Julia James",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Matthew Wu",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Leon P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Jessica Vasquez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Harold D.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Sara Walton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Erin M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Mariah Jackson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Paul G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Zachary Anderson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer K.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Amanda Fowler",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Patricia Navarro",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Elizabeth M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Natasha Carson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Todd R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Emily Vega",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ashley E.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Karen Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Bridget B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Brian Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sophia A.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Matthew Ramirez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Patrick M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Brittany Watkins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ricky S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Kimberly Sanchez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Laurie K.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Michael Hale",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "John S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Julie Love",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Ashley Barnett",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Timothy M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Benjamin Allen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Gabriel D.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Maria Sullivan",
        "service_type": "Garage Door Services",
        "tech_name": "Phillip C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Carrie Lopez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Paul G.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Kristen Ward",
        "service_type": "Garage Door Opener",
        "tech_name": "Andrew S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Brian Kelley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jessica W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Morris",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "William A.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Jeremiah Tate",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jesse P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Dawn Sanchez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Peter Rodriguez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christine R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Scott Mcclain",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Miranda R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Jessica Alexander",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Karen S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Curtis Jefferson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Steven M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Christopher Jensen",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Debra M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Thomas Walters",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mary P.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Benjamin Porter",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Holly H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Whitney Gutierrez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tonya H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Jaclyn Taylor",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Monica C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Thomas Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alexander L.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Ryan Martinez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Susan J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Crystal Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Shannon N.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Erik Hebert",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Caleb H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Jason Bennett",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kevin M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Edward Hall",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sharon H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Melinda Clayton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Katie M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Jennifer Jackson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Oscar H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Darlene Valdez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Julie R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Kelly Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Nicholas Robinson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Eduardo H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Cindy Martin",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lori N.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Gilbert Davis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Alex F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Dana Escobar",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mark M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Louis Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jessica W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Thomas Scott",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sean A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Michael Crosby",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jorge D.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Rebecca Warner",
        "service_type": "Garage Door Repair",
        "tech_name": "Timothy C.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Ebony Whitehead",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jason A.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Thomas Kim",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kathleen R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Kyle Christian",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "James E.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Jason Holland",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Matthew M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Theresa Underwood",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Matthew W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Shane Hill",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kathryn B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Andrea Nichols",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robin S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Denise Ryan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steven C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Yvonne Wong",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brenda F.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Sarah Walsh",
        "service_type": "Garage Door Installation",
        "tech_name": "Benjamin R.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Allison Bailey",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael D.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Becky Castro",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christina L.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Brett Hickman",
        "service_type": "Garage Door Opener",
        "tech_name": "Maria Z.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Kimberly Massey",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Maria G.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Thomas Alexander",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Angela C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Matthew Stone",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brad K.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Kyle Diaz",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rachel M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Jennifer Nelson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "William B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Joel Miller",
        "service_type": "Garage Door Services",
        "tech_name": "Pamela E.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Aaron Wong",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christopher W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Scott Rasmussen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lauren M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Sherry Lester",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jacqueline H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Christina Garcia",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew G.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Eric Wilson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andrew M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Robert Duarte",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jamie F.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Rebecca Sullivan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Maria R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Megan Pierce",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mark F.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Anthony Woods",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Keith R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Scott Green",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jose J.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Bridget Mendoza",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Erik Woods",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Gregory Anderson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Briana M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Jennifer Anderson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Barbara H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Ryan Garcia",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jared R.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Timothy Hall",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Vanessa H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "David Green",
        "service_type": "Garage Door Services",
        "tech_name": "Christopher C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "William Owens",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mallory K.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Diane Edwards",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jean D.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Kim Miller",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Anne K.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Timothy Newton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kevin C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Danny Crosby",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Allison P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Connie Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jose B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Daniel Campbell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christopher M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Joseph Gardner",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Austin C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Travis Rodgers",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Timothy R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Robert Williams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Bobby M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Maria Lindsey",
        "service_type": "Garage Door Services",
        "tech_name": "Leslie C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Melissa Myers",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "William R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Christopher Joseph",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brian L.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Ashley Phillips",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ashley J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Sarah Porter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mitchell Y.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Richard Gibson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Andrew K.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Richard Kirk",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert J.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Kimberly Sanders",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christopher H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Craig Ferguson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Eric C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Cheryl Craig",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Thomas S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Adrienne Henderson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "James W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Brian Anderson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sarah D.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.8,
        "customer_name": "Michael White",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Hailey G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Rachel Bruce",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michelle D.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Billy Reilly",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Monique F.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Travis Ruiz",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Megan R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Christine Goodman",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joseph C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Laura Robinson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kathryn M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Donna Cook",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Melinda D.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Ashley Wagner",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brittney B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Justin Hartman",
        "service_type": "Garage Door Services",
        "tech_name": "Christopher A.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Nicole Lopez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Abigail M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Mr. Darrell Tucker IV",
        "service_type": "Garage Door Repair",
        "tech_name": "Brian M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Stacie Sanders",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Teresa R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Mrs. Rebecca Thomas",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Linda M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Jennifer Sanchez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brenda S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Rachel Mills",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Denise S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Heather Dodson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Charles L.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Teresa Castaneda",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brenda P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "William Payne",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Debra S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Natalie Nolan",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brandon T.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Jared Jones",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "William B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Thomas Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Leah P.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Tony Moore",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Roger H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Luke Walker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jessica F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Dr. Jason Warner MD",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ashley W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Stephanie Griffith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Teresa Graves",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jennifer G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Cesar Jensen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jerry B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Monique Lane",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jared W.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Brian Macias",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Cheryl M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Brandon Hodges",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicole H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "John Gonzalez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Anne H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "April Brown",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Samuel P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "John Ford",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Daniel C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "John Clark",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stephen L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Melanie Owen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amanda L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Darrell Miller MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amy M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Eric Farrell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lisa M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Mary Holder",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Helen R.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Jose Thompson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mark M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Beth Crosby",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Anthony M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Albert Reeves",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Margaret M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Mrs. Carrie Goodman",
        "service_type": "Garage Door Installation",
        "tech_name": "Patrick J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Victoria Schmitt",
        "service_type": "Garage Door Off Track",
        "tech_name": "Eric A.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Timothy Harris",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Steven F.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Matthew Bradley Jr.",
        "service_type": "Garage Door Insulation",
        "tech_name": "Henry W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "Evan Carter",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andrew H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Michael Rodriguez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Julia Y.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Melissa Vang",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Russell J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Victoria Haley",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Roberta W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Emily Villegas",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amy B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Pamela Rodriguez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Justin G.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Ricky Patrick",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Latoya B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Tammie Lloyd",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brian L.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Walter Rivera",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Omar A.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Eric Young",
        "service_type": "Garage Door Opener",
        "tech_name": "Theresa C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Cheyenne Mcdonald",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Veronica C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Edward Rodriguez",
        "service_type": "Garage Door Services",
        "tech_name": "Chase W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Lee Caldwell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Frederick H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Wesley Gutierrez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mary B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Angela Brown",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Shelby H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "William Bonilla",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Jaclyn Oconnor",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ricardo F.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Monica Richards",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lisa L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Christopher Goodwin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Cameron R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Brandon Schwartz",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jackie M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Barbara Harris",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Leonard S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Stephanie Collins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "David B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Isabella Brown",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Darren M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Kenneth Chavez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joseph H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Stephens",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Julie Davis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Roberto S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "David Beck",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Allison J.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Jade Mullins",
        "service_type": "Garage Door Opener",
        "tech_name": "Melinda A.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Dr. Sarah Nelson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Terri M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Steven Davis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicole P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Kayla Weber",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jared M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Joann Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rhonda R.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Shelby Hamilton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lori W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Kyle Rodriguez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cody W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Albert Harmon",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Erin M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Donna Wise",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michelle G.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Brandon Warner",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lori R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Manuel Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christy T.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Dean Harvey",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Darren Baker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Dana A.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Amy Vega",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cheryl G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Kelly Hart",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "David Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michelle M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Tommy Strong",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sonya F.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Steven Brown",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ethan W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Lori Collins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sherry B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Ronald Gonzalez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jacqueline J.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Jonathan Glover",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Zachary L.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Emily Hutchinson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Courtney H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Justin Clements",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christy W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Barbara Serrano",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Matthew P.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Kenneth Li",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dylan D.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Melissa Tapia",
        "service_type": "Garage Door Repair",
        "tech_name": "Marcus D.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Luke Copeland",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kenneth F.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.8,
        "customer_name": "Zachary Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tom B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Timothy Allen",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amy A.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Erica Brown",
        "service_type": "Garage Door Repair",
        "tech_name": "Frank S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-25",
        "review_rate": 5.0,
        "customer_name": "Neil Hernandez",
        "service_type": "Garage Door Services",
        "tech_name": "Daniel P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Robert Winters",
        "service_type": "Garage Door Opener",
        "tech_name": "Carlos J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Theresa Wells",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kristi F.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Lisa Jackson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Aaron D.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Emily Olson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jessica N.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Melissa Jordan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Curtis R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Anna Lucas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Steve C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Ryan Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lisa P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Steven Foster",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Daryl C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Ann Garrison",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lorraine M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Olivia Carpenter",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sarah B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Kimberly Morrow",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Justin C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Thomas Hamilton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Samantha B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Holly Nicholson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Benjamin O.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Christopher Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kathryn Z.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Brent White",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Beverly S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Jonathan Morris",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Troy S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Carl Barker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Brian Garcia",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Timothy Kane",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "David M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Vanessa Kelley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andres W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "James Erickson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jesse B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Susan Ross",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Denise B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Joshua Monroe",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Colleen O.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Robert Welch",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kristine F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Kathy Garza",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Molly T.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Keith Oliver",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brittany C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Tammy Bennett",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Scott R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Courtney Long",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tina H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Shane Hart",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Aaron P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Kurt Alvarez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Curtis M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Robert Fuller",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Amy T.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "William Dunn",
        "service_type": "Garage Door Installation",
        "tech_name": "John W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Samantha Edwards DDS",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Donald S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Douglas Richards",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Eileen R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Brandon Cook",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David L.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Linda Vaughn",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tim W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Ronald Payne",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Nicholas H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Julie Scott",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert F.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Brooke Jackson MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Timothy L.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Denise Johnson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Craig A.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Kristen Thompson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jessica H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Melissa Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Travis R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Wanda Lin",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Bradley J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Laura Branch",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "William O.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Melissa Rios",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeremiah S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Laura Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Troy Bradford",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Heather A.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Eric Porter",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tonya M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Bryan Everett",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Bianca B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Alex Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Valerie S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Taylor Valdez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rachel D.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Keith Martin",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Marco Hamilton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Denise P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Jon Wilson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sarah N.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Julie Hill",
        "service_type": "Garage Door Opener",
        "tech_name": "Daniel D.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Renee Wright",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alvin R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "James Norman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brandi S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Carla Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Victoria T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Justin Cannon",
        "service_type": "Garage Door Opener",
        "tech_name": "Joshua H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Holly Gordon",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher P.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Angela Warren",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Allison M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Antonio Hopkins Jr.",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Courtney C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Ashley Rodriguez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Allen J.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Randall Franklin",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicholas D.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Kathy Roach",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joshua L.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Larry Fry",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brett R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "James Ortiz",
        "service_type": "Garage Door Installation",
        "tech_name": "Randy C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Erin Soto",
        "service_type": "Garage Door Installation",
        "tech_name": "Christina C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Michael Sharp",
        "service_type": "Garage Door Opener",
        "tech_name": "Jackson H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Alex Goodman",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Cassandra K.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Jason Butler",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kevin S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Beth Middleton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "William N.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Joseph Woods",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Hannah H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Luke Allison",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alan D.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Megan Rivera",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Steven B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Adam Burke",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Fernando M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Michael Hamilton",
        "service_type": "Garage Door Repair",
        "tech_name": "Billy C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Thomas Simon",
        "service_type": "Garage Door Opener",
        "tech_name": "Kelly E.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "William Taylor",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ryan M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Lisa Pugh",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Oscar L.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Krista Franklin",
        "service_type": "Garage Door Installation",
        "tech_name": "Morgan K.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Caitlin Jackson MD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Yvonne G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Travis Compton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kevin L.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Brenda Oconnor",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "George B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Brittany Carroll",
        "service_type": "Garage Door Opener",
        "tech_name": "Charles R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Clinton Wilson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ashley I.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Robert Mills",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Aaron T.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Patrick Orr",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joshua L.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Patrick Townsend",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mary G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Stephanie Larson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Cynthia A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Jorge Diaz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "James M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Shawn Watts",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sarah L.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Pamela Sanchez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Regina A.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Kiara Bates",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Stephanie M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Michelle Parks",
        "service_type": "Garage Door Opener",
        "tech_name": "Tracey E.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Christine Anderson",
        "service_type": "Garage Door Installation",
        "tech_name": "Austin F.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Rebekah Davis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amy G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Austin Peters",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Derek Hayes",
        "service_type": "Garage Door Repair",
        "tech_name": "Sarah H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Erica West",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Caleb L.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Kelly Thomas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jeffrey W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Weaver",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mike H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Kenneth Luna",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cody S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Mandy Tucker",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Christopher Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sean S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Jamie Pace",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mary P.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "William Clark",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sean M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Emily Baxter",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joseph H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Malik Clayton",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Peggy Y.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Maurice Greer",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Troy N.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Todd",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amber B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Nichole Montoya",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tyler D.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Sandra Warren MD",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jose R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Judy Stanley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brian B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Debra Delgado",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tammy D.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Nancy Knox",
        "service_type": "Garage Door Opener",
        "tech_name": "Jay C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Joe Garcia",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Justin W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Samantha Phillips",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Catherine P.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Lauren Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Debbie F.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Francis Harris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Dakota R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Krystal Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Connor G.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Chad Burns",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Anthony B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Janice Huffman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Karen J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Anthony Hines",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Nicholas Griffin",
        "service_type": "Garage Door Installation",
        "tech_name": "Walter H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Jermaine Fisher",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Vicki R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Erik Wagner",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Amy P.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "David Ford",
        "service_type": "Garage Door Services",
        "tech_name": "Paul C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Christopher Moody",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kathleen H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Ashley Moore",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Veronica M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Reginald Freeman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Matthew K.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Debra Willis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Morgan A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Christine Peters",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Crystal K.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Joshua Dickerson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Linda B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Samuel West",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Julie M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Jacob Short",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kim M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Erica Santos",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Bridget H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Scott Sanchez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amanda B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Alicia Landry",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Charlotte M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Lisa Davis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ashley R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Rhonda James",
        "service_type": "Garage Door Repair",
        "tech_name": "Brandon V.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Briana Bowen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ashley R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Eduardo Tucker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Cynthia W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Edward King",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jonathan M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Larry Sanders",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Roger P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Henry Tyler",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Morgan R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Michael Pierce",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sharon B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Jeremy Evans",
        "service_type": "Garage Door Opener",
        "tech_name": "Kyle M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Charles Morales",
        "service_type": "Custom Garage Door Design",
        "tech_name": "John W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Alexander Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Dylan S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Lindsey Lee",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ellen N.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Cassandra Francis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Scott S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Deborah Curtis",
        "service_type": "Garage Door Installation",
        "tech_name": "Kendra H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Brian Mitchell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Laura A.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Sean Maddox",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lauren V.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Sheryl Sullivan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brittany F.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Katherine Hunt",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "George Allen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Susan F.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Donna Tucker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sarah N.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Joel Price",
        "service_type": "Garage Door Services",
        "tech_name": "Whitney C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Natasha Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joseph H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Austin Leon",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Hannah D.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Eric Larson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christine W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Raymond Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ronald O.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Craig Nicholson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Emily C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Susan Wilson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brad B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Michael Richard",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Vanessa B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Alicia Figueroa",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Connie Y.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Carol Hudson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Gary W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Matthew Hill",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Andre R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Sherry James",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michelle B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Denise Gray",
        "service_type": "Garage Door Insulation",
        "tech_name": "Benjamin L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Lauren Long",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Carla Luna",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kim H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "John Soto",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Nicholas M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Stephanie Montes",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kelly M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Lori Cooper",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael D.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Joshua Walker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Latoya B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Jennifer Brown",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Barbara C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Joshua Bartlett",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Andrea Hill",
        "service_type": "Garage Door Opener",
        "tech_name": "Mitchell M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Brandy Dunlap",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer J.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Thomas Murray",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Justin C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "William Hopkins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "William M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Maria Miller",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Patricia S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Erin Bowman",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Nicole Davidson",
        "service_type": "Garage Door Installation",
        "tech_name": "Courtney W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Cameron R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Kristina Cox MD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joshua M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Anna Beltran",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Olivia G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Jose Reynolds",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Glenn H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Autumn Castillo",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Alexis Aguilar",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Anna B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Deanna Mueller",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Timothy W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Taylor Wheeler MD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Bobby K.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Jonathan Jenkins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sharon H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Randall Wilson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jacob B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Jose Lewis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sally M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Jonathan Glover",
        "service_type": "Custom Garage Door Design",
        "tech_name": "John W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Philip Wilcox",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Jason Swanson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jeffrey H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Jennifer Kelly",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Steven W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Matthew Wallace",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jonathan H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Sara Jenkins",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jennifer S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Sandra Henderson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Elizabeth W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Kristina Jones",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Gina H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Danielle Shepherd",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rebecca W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Lisa Austin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Diane M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Dawn Browning",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kim I.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Melissa Martin",
        "service_type": "Garage Door Installation",
        "tech_name": "Alexander R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Monica Gibson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tonya V.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Joseph Zimmerman",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Samantha H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Gregory Hughes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Carrie T.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Adrienne Sutton",
        "service_type": "Garage Door Installation",
        "tech_name": "Steven R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Jennifer Robinson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Charles B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Mrs. Jennifer Hoover",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sarah L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Taylor Moore",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jose H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Rebecca Benitez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Terry Mclean",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Monique G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Brittany Graham MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Marc D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Judith Hunt",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Margaret Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kathleen S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Dawn Yates",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joyce W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Michael Golden",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Edward F.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Michael Patterson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mark A.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Eric Compton",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jerry H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "William White",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Dawn G.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Aaron Lewis",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jessica W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Randy Torres",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nicholas J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "John Jones",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jason T.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Lori Barnes",
        "service_type": "Garage Door Repair",
        "tech_name": "Abigail B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Matthew Garcia",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joseph B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Bonnie Boyd",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Zachary H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Kiara Harris MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Randy Moore",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brian H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Kyle Allen",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Leon B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Tracy Garcia",
        "service_type": "Garage Door Repair",
        "tech_name": "Susan R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Chase Frost",
        "service_type": "Custom Garage Door Design",
        "tech_name": "William F.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Amber Fleming",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tara H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Todd Martin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alexander E.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Susan Campbell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amber M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Jeremy Smith PhD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Emily W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Charles Fischer",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Shelia R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Patricia Gutierrez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Charles B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Alicia Taylor",
        "service_type": "Garage Door Opener",
        "tech_name": "Cathy L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Ariel Grant",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Catherine L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Brenda Navarro",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alexandra H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Mrs. Hannah Moss",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tara S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Spencer Mcclure",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Julia D.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "James Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joshua W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Tami Cross",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Danielle W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Laura Boone",
        "service_type": "Garage Door Installation",
        "tech_name": "John M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "James Rogers",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Catherine Y.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "James Singleton",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Beth R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Amber Bray",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Alison A.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Tanya Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Cassandra Lam",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Angelica Turner",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cassandra J.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Stephen Foley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sabrina F.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "John Conley",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Aaron P.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Sylvia Kelley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jody H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Andrew Cooper",
        "service_type": "Garage Door Installation",
        "tech_name": "Karen F.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Angela Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Wendy S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Lisa Scott",
        "service_type": "Garage Door Off Track",
        "tech_name": "Valerie G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Lindsey Moore",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sandra T.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Jasmine Riggs",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Kristopher Erickson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Barbara W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Haley Marquez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christopher C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Jennifer Cordova",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Stephanie Baker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Stephen C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Deborah Jackson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brian B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Chad Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ryan B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Jennifer Mcclure",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Matthew G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Robert Dixon",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "William G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Jacob Terry",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Joyce Knight",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joanne L.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Angela Jennings",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "William B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Paul Vasquez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Derek A.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Christopher Dickerson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Stacy S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Michael Harris",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Benjamin M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Judith Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brandy C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Rachel Jimenez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Aaron M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Erica Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "John R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Sheri Woods DDS",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Christopher Mitchell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Benjamin P.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Mary Daniels",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Laurie Hernandez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Desiree S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Mitchell Pace",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kimberly C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Brooke Gutierrez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Heather T.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Derrick Mitchell",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "William C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Shannon Davis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jimmy D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Kristin Erickson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Justin N.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Katelyn Pennington",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Shannon G.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Monica Moss",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Helen R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Jonathan Schroeder",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jasmine R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Emily Newman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jacob P.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Chad Patterson",
        "service_type": "Garage Door Opener",
        "tech_name": "Ashley W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Hannah Aguilar",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Zachary G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Olivia Miller",
        "service_type": "Garage Door Services",
        "tech_name": "Sean S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Alexander Norman",
        "service_type": "Garage Door Installation",
        "tech_name": "Karen W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Gerald Ramos",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Logan A.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Michael Garcia",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Heather C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Jesse Frank",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sherri P.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Michael Holt",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jack R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Julia Medina",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Chad W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Melissa Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nathan L.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Dennis Dickson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Dalton W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Stephanie Ferguson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Charles S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Alicia Macdonald MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "April J.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Rachael Weaver",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Justin D.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Baker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "Joseph Walter DVM",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Melissa R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Hernandez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michelle L.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Angela Austin",
        "service_type": "Garage Door Opener",
        "tech_name": "Anita C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "David Mccarthy",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lisa C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Tanya Reyes",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kimberly G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Ryan Davis",
        "service_type": "Garage Door Repair",
        "tech_name": "Timothy W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Caroline Evans",
        "service_type": "Garage Door Installation",
        "tech_name": "Robert G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Nicole Blankenship",
        "service_type": "Garage Door Services",
        "tech_name": "Michael S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Allison Ball",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Patrick D.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Patricia Collins",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Doris Peters",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ashley M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Haley Soto",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Shari N.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Nathan Maxwell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Hannah J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Shaun Eaton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sarah H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Tyler Rice",
        "service_type": "Garage Door Opener",
        "tech_name": "Becky G.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Miranda Gomez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mary C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Hannah Rush",
        "service_type": "Garage Door Insulation",
        "tech_name": "Eric R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Jerry Whitehead",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Erik S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Keith Hall",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kristy H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Donna Carpenter",
        "service_type": "Garage Door Insulation",
        "tech_name": "Meagan M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Sheila Pierce",
        "service_type": "Garage Door Installation",
        "tech_name": "Crystal S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Matthew Jackson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Elizabeth J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Michael Sanchez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Eric J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Alexis Jimenez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jacqueline B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Stephanie Brooks",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Erika M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Heather Allen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jesse W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Stephanie Martin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Paul P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "David Robinson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Heather J.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Jesus Nelson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Bradley S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Deborah Suarez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andrea S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Jennifer Dickerson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michelle J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Anthony Bell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jennifer Y.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Cassandra Henry",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cody B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Marissa Castillo",
        "service_type": "Garage Door Services",
        "tech_name": "Stephanie B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Michelle Mcbride",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Daniel F.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Joseph Small",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jessica W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Madison Cobb",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Taylor G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Timothy Hahn",
        "service_type": "Garage Door Services",
        "tech_name": "Ashley L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Benjamin Walter",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Wendy Kidd MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Natasha S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Richard Macias",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alexis A.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Rachel Hall",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Gerald C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "James Hernandez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Christina Chapman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nicholas R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Travis Palmer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jane C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Ashley Morris",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Julia B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Mr. James Howell",
        "service_type": "Garage Door Installation",
        "tech_name": "Charles K.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Peter Green",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Blake Morrow",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Lane",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Danielle M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Angela Fowler",
        "service_type": "Garage Door Off Track",
        "tech_name": "Valerie M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Reginald Hardy",
        "service_type": "Garage Door Installation",
        "tech_name": "Zachary B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
